import React, { ReactNode } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import {
  makeStyles,
  useTheme,
  Theme,
  createStyles,
} from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import IconButton from "@material-ui/core/IconButton";
import BookmarkBorderOutlinedIcon from "@material-ui/icons/BookmarkBorderOutlined";

import firebase from "@Config/firebaseInit";

const useStyles = makeStyles((theme: Theme) => createStyles({}));

interface AppBarProps {
  bookmarkable?: boolean;
}

export default function PersistentDrawerLeft(props: AppBarProps) {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const [user, loading, error] = useAuthState(firebase.auth());

  return (
    <IconButton color="inherit" aria-label="bookmark" disabled={!user}>
      <BookmarkBorderOutlinedIcon />
    </IconButton>
  );
}
