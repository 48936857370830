import React from "react";
import { useRouter } from "next/router";
import {
  makeStyles,
  useTheme,
  Theme,
  createStyles,
  alpha,
} from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";

import { ResultData } from "@Types/database";
import { client } from "@Config/algoliaInit";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    search: {
      backgroundColor: alpha(theme.palette.primary.light, 0.1),
      "&:hover": {
        backgroundColor: alpha(theme.palette.primary.light, 0.05),
      },
      width: "auto",

      position: "relative",
      borderRadius: theme.shape.borderRadius,
      margin: `0 ${theme.spacing(1)}px`,

      [theme.breakpoints.down("sm")]: {
        backgroundColor: alpha("#FFF", 0.1),
        "&:hover": {
          backgroundColor: alpha("#FFF", 0.2),
        },
        width: "100%",
      },
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    inputRoot: {
      color: "inherit",
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        width: 152,
        "&:focus": {
          width: 304,
        },
      },
    },
  })
);

interface SearchBarProps {
  onSuggestion?: (hits: ResultData[], hitCount: number) => void;
  onChange?: (value: string, event: any) => void;
}

export default function SearchBar(props: SearchBarProps) {
  const router = useRouter();
  const classes = useStyles();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));

  const { onSuggestion, onChange } = props;

  const pagesIndex = client.initIndex("pages_" + router.locale);

  const handleChange = (e: any) => {
    const query = e.target.value;
    if (onChange) onChange(query, e);

    pagesIndex
      .search(query, { hitsPerPage: 5, facetFilters: ["type:-yado"] })
      .then((result: any) => {
        if (onSuggestion) onSuggestion(result.hits, result.nbHits);
      });
  };

  const keyPress = (e: any) => {
    if (e.keyCode == 13) {
      router.push(`/search?q=${e.target.value}`);
    }
  };

  return (
    <div className={classes.search}>
      <div className={classes.searchIcon}>
        <SearchIcon />
      </div>
      <InputBase
        autoFocus={mobile}
        placeholder="検索…"
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput,
        }}
        inputProps={{ "aria-label": "search" }}
        onChange={handleChange}
        onKeyDown={keyPress}
        // onFocus={handleFocus}
        // onBlur={handleBlur}
      />
    </div>
  );
}
