import React, { useEffect } from "react";
import Head from "next/head";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import {
  createStyles,
  Theme,
  makeStyles,
  useTheme,
} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

import SummaryCard, { ISOToLocalString } from "@Components/posts/summaryCard";
import AppBar from "@Components/appBar/appBar";
import Section from "@Components/posts/section";
import HeaderBlock from "@Components/posts/headerBlock";
import Footer from "@Components/common/footer";
import { client } from "@Config/algoliaInit";
import { ResultData } from "@Types/database";
import Pagination from "@Components/posts/pagination";
import SEO from "@Components/common/seo";
import DisplayAd from "@Components/ads/displayAd";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pagination: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      margin: theme.spacing(4),
    },
  })
);

interface ServersideProps {
  results: any;
}

export const getContentTypeName = (type: string): string => {
  const typesObject: { [type: string]: string } = {
    article: "記事",
    media: "メディア",
    wiki: "用語",
    yado: "宿",
  };
  return typesObject[type];
};

export async function getServerSideProps({ query, locale }: any) {
  const queryString = query.q;
  const page = parseInt(query.p) - 1 || 0;

  const pagesIndex = client.initIndex("pages_" + locale);
  const results = await pagesIndex.search(queryString, {
    page,
    facetFilters: ["type:-yado"],
  });

  return {
    props: {
      results,
      ...(await serverSideTranslations(locale, ["common"])),
    },
  };
}

export default function Home({ results }: ServersideProps) {
  const { t: t_common } = useTranslation("common");
  // const { t: t_article } = useTranslation("article");

  const router = useRouter();
  const classes = useStyles();
  const theme = useTheme();

  console.log(results);

  return (
    <React.Fragment>
      <SEO pageTitle={`検索: ${results.query}`} />

      <AppBar bookmarkable>
        <Section>
          <HeaderBlock
            title={`検索: 「${results.query}」`}
            subtitle={`ヒット: ${results.nbHits}件`}
          />

          <Grid container spacing={3}>
            {results.hits.map((item: ResultData, i: number) => {
              return (
                <Grid item sm={6} xs={12} key={i}>
                  {
                    <SummaryCard
                      title={`${getContentTypeName(item.type)}: ${item.title}`}
                      description={item.description}
                      image={item.image}
                      date={
                        item.date
                          ? ISOToLocalString(item.date, router.locale)
                          : ""
                      }
                      link={item.link !== "" ? item.link : undefined}
                    />
                  }
                </Grid>
              );
            })}

            <Grid item sm={6} xs={12} key="a">
              <DisplayAd variant="square" />
            </Grid>
          </Grid>

          <Pagination
            getURL={(page) => {
              return `/search?q=${results.query}&p=${page}`;
            }}
            componentProps={{
              count: results.nbPages,
              color: "primary",
              page: results.page + 1,
            }}
          />
        </Section>

        <Footer />
      </AppBar>
    </React.Fragment>
  );
}
