import React, { useEffect } from "react";
import { useRouter } from "next/router";
import {
  createStyles,
  Theme,
  makeStyles,
  useTheme,
} from "@material-ui/core/styles";
import Pagination, { PaginationProps } from "@material-ui/lab/Pagination";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pagination: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      margin: `${theme.spacing(4)}px 0`,
    },
  })
);

interface CustomPaginationProps {
  componentProps: PaginationProps;
  getURL: (page: number) => string;
}

export default function CustomPagination(props: CustomPaginationProps) {
  const router = useRouter();
  const classes = useStyles();
  const theme = useTheme();

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    router.push(props.getURL(value));
  };

  return (
    <div className={classes.pagination}>
      <Pagination onChange={handleChange} {...props.componentProps} />
    </div>
  );
}
