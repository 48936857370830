import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import React from "react";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    intro: {
      display: "flex",
      marginBottom: theme.spacing(4),
      alignItems: "flex-end",
      justifyContent: "space-between",
      [theme.breakpoints.down("sm")]: {
        display: "block",
      },
    },
    title: {
      width: 600,
      [theme.breakpoints.down("sm")]: {
        width: "unset",
      },
    },
  })
);

interface HeaderBlockProps {
  title: string;
  subtitle: string;
}

export default function Home(props: HeaderBlockProps) {
  const classes = useStyles();

  return (
    <div className={classes.intro}>
      <Typography
        variant="h4"
        gutterBottom
        color="primary"
        className={classes.title}
      >
        {props.title}
      </Typography>

      <Typography variant="body1" color="textPrimary">
        {props.subtitle}
      </Typography>
    </div>
  );
}
