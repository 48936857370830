import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import Skeleton from "@material-ui/lab/Skeleton";
import IconButton from "@material-ui/core/IconButton";
import TwitterIcon from "@material-ui/icons/Twitter";
import LanguageIcon from "@material-ui/icons/Language";
import InstagramIcon from "@material-ui/icons/Instagram";

const useStyles = makeStyles({
  root: {
    // maxWidth: 345,
  },
  media: {
    height: 240,
  },
  /* content: {
    height: 240,
  }, */
  title: { height: 72, overflow: "hidden" },
});

interface CardProps {
  title: string;
  subtitle?: string;
  description: string;
  image?: string;
  date?: string;
  link?: string;
  // tags?: string[];
  social?: string[];
  height?: number;
}

export const ISOToLocalString = (time: string, locale: string | undefined) => {
  const date = new Date(time);
  const localeString = locale === "ja" ? "ja-JP" : "en-US";
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "short",
    day: "numeric",
  };

  return `更新日: ${date.toLocaleDateString(localeString, options)}`;
};

export default function MediaCard(props: CardProps) {
  const classes = useStyles();

  return (
    <article itemScope itemType="http://schema.org/Article">
      <meta itemProp="name" content={props.title || "タイトル"} />
      <meta itemProp="headline" content={props.title || "タイトル"} />
      <meta itemProp="author" content={"ときやど"} />
      <meta itemProp="description" content={props.description || "概要"} />
      <meta itemProp="datePublished" content={props.date || "2021-04-01"} />
      <meta itemProp="image" content={props.image || "概要"} />
      <Card className={classes.root}>
        <CardActionArea
          href={props.link || ""}
          disabled={props.link ? false : true}
        >
          {props.image ? (
            <CardMedia
              itemProp="image"
              className={classes.media}
              image={props.image}
              title={props.title}
            />
          ) : (
            <Skeleton
              className={classes.media}
              animation={false}
              variant="rect"
            />
          )}
          <CardContent
            // className={classes.content}
            style={{ height: props.height || 240 }}
          >
            <Typography variant="body1" color="textSecondary">
              {props.date}
            </Typography>
            <div className={classes.title}>
              <Typography gutterBottom variant="h5" color="primary">
                {props.title}
              </Typography>
              {props.subtitle ? (
                <Typography gutterBottom variant="body1" color="textSecondary">
                  {"<" + props.subtitle + ">"}
                </Typography>
              ) : null}
            </div>

            <Typography variant="body2" color="textSecondary" component="p">
              {props.description}
            </Typography>
          </CardContent>
        </CardActionArea>
        {props.social ? (
          <CardActions disableSpacing>
            {props.social.map((link, i) => {
              return (
                <IconButton
                  key={i}
                  aria-label="social link"
                  href={link}
                  target="_blank"
                >
                  {link.includes("twitter") ? (
                    <TwitterIcon />
                  ) : link.includes("instagram") ? (
                    <InstagramIcon />
                  ) : (
                    <LanguageIcon />
                  )}
                </IconButton>
              );
            })}
          </CardActions>
        ) : null}
      </Card>
    </article>
  );
}
