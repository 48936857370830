import Image from "next/image";
import Link from "next/link";
import {
  createStyles,
  Theme,
  makeStyles,
  useTheme,
} from "@material-ui/core/styles";
import React from "react";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import IconButton from "@material-ui/core/IconButton";
import TwitterIcon from "@material-ui/icons/Twitter";
import InstagramIcon from "@material-ui/icons/Instagram";
import MailOutlinedIcon from "@material-ui/icons/MailOutlined";
import YouTubeIcon from "@material-ui/icons/YouTube";

import Logo from "@Components/common/logo";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(12),
      padding: 0,
      background: theme.palette.background.paper,
      color: theme.palette.primary.main,
    },
    container: {
      display: "flex",
      padding: theme.spacing(2),
      justifyContent: "space-evenly",
      textAlign: "center",
    },
    mobileHeader: {
      display: "flex",
      padding: theme.spacing(2),
      justifyContent: "space-between",
    },
    logo: { marginTop: "auto", marginBottom: "auto" },
    mobileTitle: {},
    section: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
    },
    sectionHeader: {
      marginBottom: theme.spacing(1),
    },
    link: {
      marginBottom: theme.spacing(1),
    },
    copyright: {
      display: "flex",
      flexDirection: "column",
      alignItems: "end",
      padding: theme.spacing(1),
    },
    sectionFooter: {
      margin: "auto",
    },
  })
);

const socialLinks = [
  { icon: <TwitterIcon />, link: "https://twitter.com/rninopon" },
  { icon: <InstagramIcon />, link: "https://www.instagram.com/toki.yado/" },
  {
    icon: <YouTubeIcon />,
    link: "https://youtube.com/channel/UCCMumC3J3J4iBApwsSeq6Sw",
  },
  { icon: <MailOutlinedIcon />, link: "mailto:tokiyado@gmail.com" },
];

function Social() {
  return (
    <nav>
      {socialLinks.map((item, i) => {
        return (
          <IconButton
            key={i}
            color="primary"
            aria-label="social link"
            href={item.link || "/"}
            target="_blank"
            disabled={!item.link}
          >
            {item.icon}
          </IconButton>
        );
      })}
    </nav>
  );
}

const footerLinks = [
  {
    title: "コンテンツ",
    links: [
      {
        title: "宿マップ",
        link: "/database",
      },
      {
        title: "デジタルコンテンツ",
        link: "/media",
      },
      {
        title: "記事一覧",
        link: "/posts",
      },
      {
        title: "用語集",
        link: "/wiki/a",
      },
    ],
  },
  {
    title: "ときやど",
    links: [
      {
        title: "ときやどについて",
        link: "/about",
      },
      {
        title: "お問い合わせ",
        link: "/contact",
      },
      {
        title: "プライバシー",
      },
      {
        title: "支援",
      },
    ],
  },
];

export default function HeroContents(props: any) {
  const classes = useStyles();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <footer className={classes.root}>
      <Container>
        {mobile ? (
          <div className={classes.mobileHeader}>
            <Logo />
            <Social />
          </div>
        ) : null}

        <div className={classes.container}>
          {!mobile ? (
            <div className={classes.section}>
              <Logo />
            </div>
          ) : null}

          {footerLinks.map((section, i) => {
            return (
              <nav key={i} className={classes.section}>
                <Typography
                  className={classes.sectionHeader}
                  variant="subtitle1"
                >
                  <b>{section.title}</b>
                </Typography>

                {section.links.map((item, j) => {
                  return item.link ? (
                    <div key={j} className={classes.link}>
                      <Link href={item.link}>{item.title}</Link>
                    </div>
                  ) : null;
                })}
              </nav>
            );
          })}

          {!mobile ? (
            <div className={classes.section}>
              <Social />
            </div>
          ) : null}
        </div>

        <div className={classes.copyright}>
          <Typography className={classes.sectionFooter} variant="body2">
            © 2021 Tokiyado Project
          </Typography>
        </div>
      </Container>
    </footer>
  );
}
