import React from "react";
import { useRouter } from "next/router";
import Image from "next/image";
import {
  makeStyles,
  useTheme,
  Theme,
  createStyles,
} from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import Fab from "@material-ui/core/Fab";
import Popper from "@material-ui/core/Popper";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import CloseIcon from "@material-ui/icons/Close";

import SearchBar from "@Components/appBar/searchBar";
import { getContentTypeName } from "@Pages/search";
import { ResultData } from "@Types/database";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    // Common
    container: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      margin: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    },
    list: {
      height: 96,
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    },
    // Mobile
    appBar: {},
    offset: theme.mixins.toolbar,

    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    // Desktop
    popper: {
      width: 360,
      zIndex: theme.zIndex.appBar + 1,
      top: theme.spacing(1),
      backgroundColor: "#FFF",
    },
  })
);

export default function SearchModal() {
  const router = useRouter();
  const classes = useStyles();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));

  // Common
  const [suggestions, setSuggestions] = React.useState<ResultData[]>([]);
  const [hitCount, setHitCount] = React.useState(0);
  const [query, setQuery] = React.useState("");
  const handleSuggestion = (hits: ResultData[], count: number) => {
    setSuggestions(hits);
    setHitCount(count);
    console.log(hits);
  };
  const handleClick = (link: string) => {
    router.push(link);
  };

  // Mobile
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleInputMobile = (query: string) => {
    setQuery(query);
  };

  // Desktop
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleInputDesktop = (query: string, event: any) => {
    setQuery(query);
    if (query) {
      setAnchorEl(event.currentTarget);
    } else {
      setAnchorEl(null);
    }
  };

  return mobile ? (
    <React.Fragment>
      <IconButton color="primary" onClick={handleClickOpen}>
        <SearchIcon />
      </IconButton>

      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        // TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar} position="fixed">
          <Toolbar>
            <SearchBar
              onSuggestion={handleSuggestion}
              onChange={handleInputMobile}
            />

            <IconButton
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>

        <div className={classes.offset} />

        <div className={classes.container}>
          <Image
            layout="fixed"
            src="/images/algolia/search-by-algolia-light-background.svg"
            alt="search by algolia"
            width={168}
            height={24}
          />

          <Fab
            disabled={query === ""}
            variant="extended"
            color="primary"
            size="small"
            aria-label="search"
            href={`/search?q=${query}`}
          >
            <SearchIcon />
            {`すべて見る (${hitCount})`}
          </Fab>
        </div>

        <List>
          {suggestions.length !== 0 ? (
            suggestions.map((item, i) => {
              return (
                <React.Fragment key={i}>
                  <ListItem
                    button
                    className={classes.list}
                    //href={item.link}
                    onClick={() => handleClick(item.link)}
                  >
                    <ListItemAvatar>
                      <Avatar
                        alt={item.title}
                        variant="rounded"
                        src={item.image}
                        color="primary"
                      />
                    </ListItemAvatar>

                    <ListItemText
                      primary={`${getContentTypeName(item.type)}: ${
                        item.title
                      }`}
                      secondary={item.description}
                    />
                  </ListItem>
                  <Divider />
                </React.Fragment>
              );
            })
          ) : (
            <ListItem className={classes.list}>
              <ListItemText
                primary="候補なし"
                secondary="ほかのキーワードをお試しください"
              />
            </ListItem>
          )}
        </List>
      </Dialog>
    </React.Fragment>
  ) : (
    <React.Fragment>
      <SearchBar
        onSuggestion={handleSuggestion}
        onChange={handleInputDesktop}
      />

      <Popper
        className={classes.popper}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        placement="bottom-end"
        transition
      >
        <div className={classes.container}>
          <Image
            layout="fixed"
            src="/images/algolia/search-by-algolia-light-background.svg"
            alt="search by algolia"
            width={140}
            height={20}
          />

          <Fab
            disabled={hitCount === 0}
            variant="extended"
            color="primary"
            size="small"
            aria-label="search"
            href={`/search?q=${query}`}
          >
            <SearchIcon />
            {`すべて見る (${hitCount})`}
          </Fab>
        </div>

        <List>
          {suggestions.length !== 0 ? (
            suggestions.map((item, i) => {
              return (
                <React.Fragment key={i}>
                  <ListItem
                    button
                    className={classes.list}
                    //href={item.link}
                    onClick={() => handleClick(item.link)}
                  >
                    <ListItemAvatar>
                      <Avatar
                        alt={item.title}
                        variant="rounded"
                        src={item.image}
                        color="primary"
                      />
                    </ListItemAvatar>

                    <ListItemText
                      primary={`${getContentTypeName(item.type)}: ${
                        item.title
                      }`}
                      secondary={item.description}
                    />
                  </ListItem>
                  <Divider />
                </React.Fragment>
              );
            })
          ) : (
            <ListItem className={classes.list}>
              <ListItemText
                primary="候補なし"
                secondary="ほかのキーワードをお試しください"
              />
            </ListItem>
          )}
        </List>
      </Popper>
    </React.Fragment>
  );
}
