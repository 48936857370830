import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "grid",
      // gridTemplateColumns: "80px minmax(0,1fr) 80px",
      // gridTemplateRows: "minmax(270px,auto) 1fr",
      margin: "auto",
      maxWidth: 800,
      [theme.breakpoints.down("sm")]: {
        display: "block",
        gridTemplateColumns: "80px 452px 80px",
        justifyContent: "center",
        padding: theme.spacing(2),
      },
    },
    section: {
      // gridColumn: "2 / 3",
      // gridRow: "1 / 3",
      // padding: theme.spacing(2),
      "& img": {
        maxWidth: "100%",
      },
    },
  })
);

interface SectionProps {
  children?: React.ReactNode;
}

export default function Section(props: SectionProps) {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.section}>{props.children}</div>
    </div>
  );
}
