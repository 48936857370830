import React, { ReactNode } from "react";
import Image from "next/image";
import Link from "next/link";
import {
  makeStyles,
  useTheme,
  Theme,
  createStyles,
} from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";

import Drawer from "@Components/appBar/drawer";
import AccountButton from "@Components/appBar/accountButton";
import BookmarkButton from "@Components/appBar/bookmarkButton";
import SearchModal from "@Components/appBar/searchModal";
import ThemeButton from "@Components/appBar/themeButton";
import Logo from "@Components/common/logo";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    appBar: {
      padding: "0 8px",
      background: "#FFF",
      color: "#296A8B",
    },
    toolbar: {
      minHeight: 48,
      padding: 0,
    },
    grow: {
      flexGrow: 1,
    },
    small: {
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    content: {
      width: "100%",
      marginRight: "auto",
      marginLeft: "auto",
      /*[theme.breakpoints.down("sm")]: {
        margin: theme.spacing(2),
      },*/
    },
  })
);

interface AppBarProps {
  children?: ReactNode;
  noMargin?: boolean;
  bookmarkable?: boolean;
}

export default function PersistentDrawerLeft(props: AppBarProps) {
  const classes = useStyles();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            color="inherit"
            aria-label="open-drawer"
            onClick={handleDrawerOpen}
          >
            <MenuIcon />
          </IconButton>

          <Logo />

          <div className={classes.grow} />

          <SearchModal />

          <ThemeButton />

          {props.bookmarkable ? <BookmarkButton /> : null}

          <AccountButton />
        </Toolbar>
      </AppBar>
      <Drawer
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      />
      <main
        className={classes.content}
        style={{ marginTop: props.noMargin ? 0 : 80 }}
      >
        {props.children}
      </main>
    </div>
  );
}
